<template>
    <div>
        <flickity id="Slider" class="c-slider" ref="slider" :options="sliderOptions" v-images-loaded:on.progress="imgsLoaded">
            <figure class="c-slider__item" @click="openFullscreen(index)" v-for="(item, index) in comp.settings.repeater" :key="item.value.img._id">
                <img class="c-slider__image" :src="siteURL + item.value.img.path">
            </figure>
        </flickity>

        <div class="c-modal" :class="{'c-modal--is-open' : showGallery}">
            <button class="c-modal__close" @click="closeFullscreen">&#x00D7;</button>
            <div class="c-modal__inner">
                <flickity id="fullScreenSlider" class="c-slider c-slider--fullscreen" ref="sliderFullscreen" :options="sliderFullOptions" v-images-loaded:on.progress="imgsLoaded">
                    <figure class="c-slider__item c-slider__item--full" v-for="item in comp.settings.repeater" :key="item.value.img._id">
                        <img class="c-slider__image c-slider__image--full" :src="siteURL + item.value.img.path">
                        <figcaption class="c-slider__description">{{ item.value.txt }}</figcaption>
                    </figure>
                </flickity>
            </div>
        </div>
    </div>
</template>

<script>
  import Flickity from 'vue-flickity'
  import 'flickity-as-nav-for'
  import imagesLoaded from 'vue-images-loaded'
  import { EventBus } from '@/event-bus'

  export default {
    name: 'Comp_customGallery',
    props: {
      comp: Object
    },

    directives: {
      imagesLoaded
    },

    data () {
      return {
        siteURL: null,
        showGallery: false,
        sliderOptions: {
          cellAlign: 'left',
          pageDots: false,
          asNavFor: '#fullScreenSlider'
        },
        sliderFullOptions: {
          cellAlign: 'center',
          pageDots: false
        }
      }
    },

    created () {
      this.siteURL = process.env.VUE_APP_UPLOADS_URL
    },

    methods: {
      imgsLoaded () {
        EventBus.$emit('pageLoaded', true)
        let sliderInstance = this.$refs.slider
        let sliderFullInstance = this.$refs.sliderFullscreen
        sliderInstance.reloadCells()
        sliderFullInstance.reloadCells()
      },

      openFullscreen () {
        this.showGallery = true
      },

      closeFullscreen () {
        this.showGallery = false
      }
    },

    components: {
      'flickity': Flickity
    }
  }
</script>
